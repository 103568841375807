import React, { Fragment } from "react";
import isNil from "lodash/isNil";

import Typography from "@ui-kit/Typography";
import { format_number } from "@helpers/format_number";

interface MoneyItemProps {
  label: string;
  value: number | null | undefined;
  valueTo?: number | null;
}

export const MoneyItem: React.FC<MoneyItemProps> = ({
  label,
  value,
  valueTo,
  ...props
}) => {
  return (
    <Typography gutterBottom {...props}>
      <Typography bolded variant="inherit">
        {label}: {value === null && "---"}
      </Typography>
      {!isNil(value) && (
        <Fragment>
          {format_number(value, { format: "$0,0" })}
          {!!valueTo && (
            <Fragment>
              {` - ${format_number(valueTo, { format: "$0,0" })}`}
            </Fragment>
          )}
        </Fragment>
      )}
    </Typography>
  );
};
