import React, { useCallback, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import isNil from "lodash/isNil";

import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import { Spacer } from "@ui-kit/Spacer";
import Button from "@ui-kit/Button";

import { MoneyItem } from "@pages/DeepDivePanel/components/MoneyItem";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";
import { useCss } from "./styles";
import {
  formatDiscountValue,
  getFacilityTuitionString,
  isAdmin,
  isPrivateSchool,
} from "@selectors";
import { tuitionWithdrawalMap } from "@constants/tuition-withdrawal";
import { tuitionWaitlistMap } from "@constants/tuition-waitlist";
import { Models } from "@services/api";
import { paymentPeriodTypesMap } from "@constants/tuition";
import { format_number } from "@helpers/format_number";
import { isAustralia } from "@selectors/facility";
import { useUserStore } from "@store/UserStore";

const Tuition: React.FC = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [{ facility }] = useDeepDivePanelStore();
  const [{ user }] = useUserStore();
  const css = useCss();

  const closeDialog = useCallback(() => {
    history.replace({
      pathname: `/map/${facility.id}`,
      search,
    });
  }, [history, search]);

  return (
    <Dialog
      open
      onClose={closeDialog}
      data-test="tuition-dialog"
      tier={facility.subscriptionTier}
      title="Tuition & fees"
    >
      <div css={css.root} data-test="tuition-info-page">
        <Typography gutterBottom data-test="tuition-value-display">
          {!isNil(facility.tuitionInfo.costPeriod) ? (
            <Fragment>
              {facility.tuitionInfo.costPeriod === Models.TuitionTypeID.Free ? (
                <Fragment>
                  <Typography variant="inherit" bolded>
                    Tuition:
                  </Typography>{" "}
                  free of charge
                </Fragment>
              ) : (
                <Fragment>
                  <Typography variant="inherit" bolded>
                    {
                      paymentPeriodTypesMap[facility.tuitionInfo.costPeriod]
                        .title
                    }
                    :
                  </Typography>{" "}
                  {getFacilityTuitionString(facility) || "---"}
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Fragment>
              {isPrivateSchool(facility) ? (
                <Fragment>
                  <Typography variant="inherit" bolded>
                    Annual tuition:
                  </Typography>{" "}
                  ---
                </Fragment>
              ) : (
                <Fragment>
                  <Typography variant="inherit" bolded>
                    Monthly tuition:
                  </Typography>{" "}
                  ---
                </Fragment>
              )}
            </Fragment>
          )}
        </Typography>

        <Typography gutterBottom>
          <Typography bolded variant="inherit">
            Tuition schedule:
          </Typography>{" "}
          {facility.tuitionInfo.scheduleFile.original ? (
            <a
              className="g-link"
              target="_blank"
              rel="noreferrer"
              href={facility.tuitionInfo.scheduleFile.original}
            >
              click to view
            </a>
          ) : (
            <Typography variant="inherit" color="textSecondary">
              not available
            </Typography>
          )}
        </Typography>

        {!!facility.tuitionInfo.costDescription && (
          <Fragment>
            <Spacer size="small" />
            <Typography italic data-test="costDescription-display">
              {facility.tuitionInfo.costDescription}
            </Typography>
          </Fragment>
        )}

        {isPrivateSchool(facility) && (
          <>
            <Spacer size="small" />
            {!isNil(facility.yearlyDaysOff) && (
              <Typography gutterBottom data-test="days-closed-display">
                <Typography bolded variant="inherit">
                  School calendar:
                </Typography>{" "}
                {facility.yearlyDaysOff} days off per school yr
              </Typography>
            )}
            {!isAustralia(facility) &&
              !isNil(facility.trueTuitionFrom) &&
              !isNil(facility.trueTuitionTo) && (
                <>
                  <Typography gutterBottom>
                    <Typography bolded variant="inherit">
                      True cost:
                    </Typography>{" "}
                    {format_number(facility.trueTuitionFrom / 100, {
                      format: "$0,0",
                    })}
                    {facility.trueTuitionFrom !== facility.trueTuitionTo
                      ? ` - ${format_number(facility.trueTuitionTo / 100, {
                          format: "$0,0",
                        })}`
                      : null}{" "}
                    per school day
                  </Typography>
                  <Typography italic>
                    By taking the school calendar into account, true cost helps
                    parents more accurately compare tuition expenses between
                    schools
                  </Typography>
                </>
              )}
          </>
        )}

        <Spacer size="medium" />

        <Typography variant="h5" align="center" css={css.sectionTitle}>
          Tuition discounts
        </Typography>

        <Spacer size="medium" />

        <Typography gutterBottom data-test="sibling-discount-display">
          <Typography bolded variant="inherit">
            Sibling discount:
          </Typography>{" "}
          {!facility.tuitionInfo.discountsSiblings ? (
            <Typography variant="inherit" color="error" bolded>
              N
            </Typography>
          ) : facility.tuitionInfo.discountsSiblingsValue?.length ? (
            formatDiscountValue(facility.tuitionInfo.discountsSiblingsValue)
          ) : (
            <Typography variant="inherit" color="success" bolded>
              Y
            </Typography>
          )}
        </Typography>

        <Typography gutterBottom data-test="military-discount-display">
          <Typography bolded variant="inherit">
            Military discount:
          </Typography>{" "}
          {!facility.tuitionInfo.discountsMilitary ? (
            <Typography variant="inherit" color="error" bolded>
              N
            </Typography>
          ) : facility.tuitionInfo.discountsMilitaryValue?.length ? (
            formatDiscountValue(facility.tuitionInfo.discountsMilitaryValue)
          ) : (
            <Typography variant="inherit" color="success" bolded>
              Y
            </Typography>
          )}
        </Typography>

        <Typography gutterBottom data-test="corporate-discount-display">
          <Typography bolded variant="inherit">
            Corporate discount:
          </Typography>{" "}
          {!facility.tuitionInfo.discountsCorporate ? (
            <Typography variant="inherit" color="error" bolded>
              N
            </Typography>
          ) : facility.tuitionInfo.discountsCorporateValue?.length ? (
            formatDiscountValue(facility.tuitionInfo.discountsCorporateValue)
          ) : (
            <Typography variant="inherit" color="success" bolded>
              Y
            </Typography>
          )}
        </Typography>

        {!!facility.tuitionInfo.discountsDescription && (
          <Fragment>
            <Spacer size="small" />
            <Typography data-test="discount-description-display" italic>
              {facility.tuitionInfo.discountsDescription}
            </Typography>
          </Fragment>
        )}

        <Spacer size="medium" />

        <Typography variant="h5" align="center" css={css.sectionTitle}>
          Fees schedule
        </Typography>

        <Spacer size="medium" />

        <MoneyItem
          label="Application fee"
          value={
            !isNil(facility.tuitionInfo.feesApplication)
              ? facility.tuitionInfo.feesApplication / 100
              : null
          }
          data-test="application-fees-display"
        />

        {!isNil(facility.tuitionInfo.feesRegistration) && (
          <MoneyItem
            label="Registration fee"
            value={facility.tuitionInfo.feesRegistration / 100}
            data-test="registration-fees-display"
          />
        )}

        {!isNil(facility.tuitionInfo.feesEnrollmentDeposit) && (
          <MoneyItem
            label="Enrollment deposit"
            value={facility.tuitionInfo.feesEnrollmentDeposit / 100}
            data-test="enrollment-fees-display"
          />
        )}

        {(!isNil(facility.tuitionInfo.feesSupplies) ||
          !isNil(facility.tuitionInfo.feesActivity)) && <Spacer size="small" />}

        {!isNil(facility.tuitionInfo.feesSupplies) && (
          <MoneyItem
            label="Supplies fee"
            value={facility.tuitionInfo.feesSupplies / 100}
            data-test="supplies-fees-display"
          />
        )}
        {!isNil(facility.tuitionInfo.feesActivity) && (
          <MoneyItem
            label="Activity fee"
            value={facility.tuitionInfo.feesActivity / 100}
            data-test="activity-fees-display"
          />
        )}

        {(!isNil(facility.tuitionInfo.feesEarlyDropOff) ||
          !isNil(facility.tuitionInfo.feesLatePickUp)) && (
          <Spacer size="small" />
        )}

        {!isNil(facility.tuitionInfo.feesEarlyDropOff) && (
          <MoneyItem
            label="Early drop-off fee"
            value={facility.tuitionInfo.feesEarlyDropOff / 100}
            data-test="feesEarlyDropOff-display"
          />
        )}
        {!isNil(facility.tuitionInfo.feesLatePickUp) && (
          <MoneyItem
            label="Late pick-up fee"
            value={facility.tuitionInfo.feesLatePickUp / 100}
            data-test="feesLatePickUp-display"
          />
        )}

        {!isNil(facility.tuitionInfo.feesDescription) && (
          <Fragment>
            <Spacer size="small" />
            <Typography italic data-test="feesDescription-display">
              {facility.tuitionInfo.feesDescription}
            </Typography>
          </Fragment>
        )}

        <Spacer size="medium" />

        <Typography variant="h5" align="center" css={css.sectionTitle}>
          Waitlist information
        </Typography>

        <Spacer size="medium" />

        <Typography gutterBottom data-test="waitlistProvided-display">
          <Typography bolded variant="inherit">
            Waitlist:
          </Typography>{" "}
          {isNil(facility.tuitionInfo.waitlistProvided)
            ? "---"
            : tuitionWaitlistMap[facility.tuitionInfo.waitlistProvided].name}
        </Typography>

        {!isNil(facility.tuitionInfo.waitlistFee) && (
          <MoneyItem
            label="Waitlist fee"
            value={facility.tuitionInfo.waitlistFee / 100}
            data-test="waitlistFee-display"
          />
        )}

        {!!facility.tuitionInfo.waitlistDescription && (
          <Fragment>
            <Spacer size="small" />
            <Typography italic data-test="waitlistDescription-display">
              {facility.tuitionInfo.waitlistDescription}
            </Typography>
          </Fragment>
        )}

        <Spacer size="medium" />

        <Typography variant="h5" align="center" css={css.sectionTitle}>
          Withdrawal policy
        </Typography>

        <Spacer size="medium" />

        <Typography
          gutterBottom
          data-test="withdrawalPolicyNoticePeriod-display"
        >
          <Typography bolded variant="inherit">
            Advance notice:
          </Typography>{" "}
          {isNil(facility.tuitionInfo.withdrawalPolicyNoticePeriod)
            ? "---"
            : tuitionWithdrawalMap[
                facility.tuitionInfo.withdrawalPolicyNoticePeriod
              ].name}
        </Typography>

        {!!facility.tuitionInfo.withdrawalPolicyDescription && (
          <Fragment>
            <Spacer size="small" />
            <Typography italic data-test="withdrawalPolicyDescription-display">
              {facility.tuitionInfo.withdrawalPolicyDescription}
            </Typography>
          </Fragment>
        )}

        {(facility.isEditable || isAdmin(user)) && (
          <>
            <Spacer size="md" />
            <Button
              color={
                facility.subscriptionTier === Models.SubscriptionTier.Pro
                  ? "black"
                  : "primary"
              }
              variant="outlined"
              size="large"
              fullWidth
              link={`/map/${facility.id}/edit/tuition-and-fees`}
            >
              Edit tuition & fees
            </Button>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default Tuition;
